import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GlobalService} from '../../global.service';
import {AuthenticationService} from '../../authentication.service';
import {EventsService} from '../events/events.service';
import {teamsService} from '../teams/teams.service';

@Injectable({
  providedIn: 'root'
})
export class TeamEventsService {

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService,
    private auth: AuthenticationService,
    private eventsService: EventsService
  ) { }

  saveBopBudget(event_id, budget, team_id) {
    return this.httpClient.post(this.globalService.apiUrl + '/teamevents/' + event_id + '/saveBudget/' + team_id, {budget: budget});
  }

  getTeamEvents() {
    return this.httpClient.get(this.globalService.apiUrl + '/teamevents');
  }

  getTeamEventsByRaces() {
    return this.httpClient.get(this.globalService.apiUrl + '/getTeamEventsByRaces');
  }

  getTeamEventsArchive() {
    return this.httpClient.get(this.globalService.apiUrl + '/teameventsarchive');
  }

  getEvent(id) {
    return this.httpClient.get(this.globalService.apiUrl + '/teamevents/' + id );
  }

  signup(signup, id) {
    return this.httpClient.post(this.globalService.apiUrl + '/teamevents/' + id + '/signup', {team: signup});
  }

  signupSeasonRace(signup, id, race) {
    return this.httpClient.post(this.globalService.apiUrl + '/teamevents/' + id + '/signup', {team: signup, 'race' : race});
  }

  raceLineUpSave(lineup, event, race, p_id) {
    return this.httpClient.post(this.globalService.apiUrl + '/teamevents/' + event + '/raceLineUp', {lineup: lineup, race: race, p_id: p_id});
  }

  getRegistration(event) {
    return this.httpClient.get(this.globalService.apiUrl + '/teamevents/' + event + '/getRegistration' );
  }

  getRegistrationAdmin(event, teamid) {
    return this.httpClient.get(this.globalService.apiUrl + '/teamevents/' + event + '/getRegistrationAdmin/' + teamid );
  }

  getTeamSeasonBudgetBopSettings(event, p_id) {
    return this.httpClient.get(this.globalService.apiUrl + '/teamevents/' + event + '/teamBudgetSettings/' + p_id );
  }

  checkQualifiers(event) {
    return this.httpClient.get(this.globalService.apiUrl + '/teamevents/' + event + '/checkQualifier');
  }

  checkRaceTeamAdmin(race) {
    return this.httpClient.get(this.globalService.apiUrl + '/teamevents/' + race + '/checkRaceTeamAdmin' );
  }

  getRegistrationSeason(event, race) {
    return this.httpClient.get(this.globalService.apiUrl + '/teamevents/' + event + '/getSeasonRaceRegistration/' + race );
  }

  getAvailableClasses(event, race) {
    return this.httpClient.get(this.globalService.apiUrl + '/teamevents/' + event + '/getAvailableClasses/' + race );
  }

  getPenaltyPoints(event) {
    return this.httpClient.get(this.globalService.apiUrl + '/teamevents/' + event + '/penaltyPoints' );
  }

  withdraw(event, p_id) {
    return this.httpClient.post(this.globalService.apiUrl + '/teamevents/' + event + '/withdraw', {team: p_id});
  }

  withdrawSeasonRace(event, race) {
    return this.httpClient.get(this.globalService.apiUrl + '/teamevents/' + event + '/withdrawSeasonRace/' + race);
  }

  checkInvitationCode(event, code) {

    return this.httpClient.post(this.globalService.apiUrl + '/teamevents/' + event + '/checkInvitationCode', {code: code});

  }


}
