import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { authRoutes } from './pages/auth/auth.routing';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard-statistics/dashboard-statistics.module').then(m => m.DashboardStatisticsModule),
        pathMatch: 'full'
      },
      {
        path: 'acc-connector',
        loadChildren: () => import('./pages/acc-connector/acc-connector.module').then(m => m.AccConnectorModule),
        pathMatch: 'full'
      },
      {
        path: 'events',
        loadChildren: () => import('./pages/events/events.module').then(m => m.EventsModule),
        pathMatch: 'prefix'
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
        pathMatch: 'prefix'
      },
      {
        path: 'team',
        loadChildren: () => import('./pages/team/team.module').then(m => m.TeamModule),
        pathMatch: 'prefix'
      },
      {
        path: 'teams',
        loadChildren: () => import('./pages/teams/teams.module').then(m => m.TeamsModule),
        pathMatch: 'prefix'
      },
      {
        path: 'tracks',
        loadChildren: () => import('./pages/tracks/tracks.module').then(m => m.TracksModule),
        pathMatch: 'prefix'
      },
      {
        path: 'datenschutz',
        loadChildren: () => import('./pages/datenschutz/datenschutz.module').then(m => m.DatenschutzModule),
        pathMatch: 'full'
      },
      {
        path: 'impressum',
        loadChildren: () => import('./pages/impressum/impressum.module').then(m => m.ImpressumModule),
        pathMatch: 'full'
      },
      {
        path: 'hotlaps',
        loadChildren: () => import('./pages/hotlaps/hotlaps.module').then(m => m.HotlapsModule),
        pathMatch: 'prefix'
      },
      {
        path: 'seasons',
        loadChildren: () => import('./pages/seasonsv2/seasonsv2.module').then(m => m.Seasonsv2Module),
        pathMatch: 'prefix'
      },
      {
        path: 'seasonsv2',
        loadChildren: () => import('./pages/seasonsv2/seasonsv2.module').then(m => m.Seasonsv2Module),
        pathMatch: 'prefix'
      },
      {
        path: 'statistics',
        loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule),
        pathMatch: 'prefix'
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
        pathMatch: 'prefix'
      },

      {
        path: 'admin',
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
        pathMatch: 'prefix'
      },
      {
        path: 'chat',
        loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule),
        pathMatch: 'prefix'
      },
      {
        path: 'search',
        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule),
        pathMatch: 'prefix'
      },
      {
        path: 'endurance',
        loadChildren: () => import('./pages/team-events/team-events.module').then(m => m.TeamEventsModule),
        pathMatch: 'prefix'
      },
      {
        path: 'merchandise',
        loadChildren: () => import('./pages/merchandise/merchandise.module').then(m => m.MerchandiseModule),
        pathMatch: 'full'
      },
      {
        path: 'reko-application',
        loadChildren: () => import('./pages/reko-application/reko-application.module').then(m => m.RekoApplicationModule),
        pathMatch: 'full'
      },
      {
        path: 'acctv',
        loadChildren: () => import('./pages/acctv/acctv.module').then(m => m.AcctvModule),
        pathMatch: 'full'
      },
      {
        path: 'accdrive',
        loadChildren: () => import('./pages/accdrive/accdrive.module').then(m => m.AccdriveModule),
        pathMatch: 'full'
      },
      {
        path: 'invalid-license',
        loadChildren: () => import('./pages/invalid-license/invalid-license.module').then(m => m.InvalidLicenseModule),
        pathMatch: 'full'
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/faq-page/faq-page.module').then(m => m.FaqPageModule),
        pathMatch: 'full'
      },
      {
        path: 'patreon',
        loadChildren: () => import('./pages/patreon/patreon.module').then(m => m.PatreonModule),
        pathMatch: 'full'
      },
      {
        path: 'livery-tool',
        loadChildren: () => import('./pages/livery-tool/livery-tool.module').then(m => m.LiveryToolModule),
        pathMatch: 'full'
      },
      {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
        pathMatch: 'full'
      }



    ]
  },
  {
    path: 'auth',
    children: [
      ...authRoutes
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // initialNavigation: 'enabled',
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
