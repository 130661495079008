import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardSocialsComponent} from './dashboard-socials.component';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';


@NgModule({
  declarations: [DashboardSocialsComponent],
  imports: [
    CommonModule,
    FlexModule,
    FlexLayoutModule
  ],
  exports: [
    DashboardSocialsComponent
  ]
})
export class DashboardSocialsModule { }
