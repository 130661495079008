import { Component, OnInit } from '@angular/core';
import { SidenavItem } from '../sidenav/sidenav-item/sidenav-item.model';
import * as fromRoot from '../../reducers/index';
import * as fromSidenav from '../sidenav/shared/sidenav.action';
import { SetCurrentlyOpenByRouteAction } from '../sidenav/shared/sidenav.action';
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SelectLayoutAction, SetCardElevationAction } from '../layout/shared/layout.action';
import {HttpRequest} from '@angular/common/http';

@Component({
  selector: 'elastic-route-handler',
  templateUrl: './route-handler.component.html',
  styleUrls: ['./route-handler.component.scss']
})
export class RouteHandlerComponent implements OnInit {

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private route: ActivatedRoute
  ) {




  }

  ngOnInit() {
    // Set Sidenav Currently Open on Page load
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.store.dispatch(new SetCurrentlyOpenByRouteAction(event.urlAfterRedirects));
      }
    });
    // SET DEFAULT LAYOUT
    this.store.dispatch(new SelectLayoutAction('gamma'));

    // You can use ?layout=beta to load the page with Layout Beta as default
    // Same with ?elevation=5 (anything from 0 to 24)
    this.route.queryParamMap.subscribe((params) => {
      const layout = params.get('layout');

      switch (layout) {
        case 'alpha': {
          this.store.dispatch(new SelectLayoutAction('alpha'));
          break;
        }

        case 'beta': {
          this.store.dispatch(new SelectLayoutAction('beta'));
          break;
        }

        case 'gamma': {
          this.store.dispatch(new SelectLayoutAction('gamma'));
          break;
        }
      }

      const elevation = params.get('elevation');

      if (elevation) {
        this.store.dispatch(new SetCardElevationAction('card-elevation-z' + elevation));
      }
    });

    const team = new SidenavItem({
      name: 'LFM Team',
      icon: 'people',
      route: '/team',
      subItems: [ ],
      position: 1
    });

    const esports = new SidenavItem({
      name: 'LFM eSports',
      icon: 'people',
      route: '/team/esports',
      subItems: [ ],
      position: 1
    });

    const events = new SidenavItem({
      name: 'Events',
      icon: 'events',
      route: '/events/single-driver',
      subItems: [ ],
      position: 1
    });

    const eventSubs = [
      new SidenavItem({
        name: 'LFM Endurance & Team Events',
        route: '/endurance',
        icon: 'av_timer',
        parent: events,
        subItems: [ ],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        }
      }),
      new SidenavItem({
        name: 'LFM Single Driver Cups',
        route: '/events/single-driver',
        icon: 'events',
        parent: events,
        subItems: [ ],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        }
      })
      ,
      new SidenavItem({
        name: 'Hosted Events',
        route: '/events/hosted',
        icon: 'av_timer',
        parent: events,
        subItems: [ ],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        }
      }),
      new SidenavItem({
        name: 'Sim Grid Events',
        route: '/events/simgrid',
        icon: 'av_timer',
        parent: events,
        subItems: [ ],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        }
      }),
      ];

    events.subItems.push(...eventSubs);

    const faq = new SidenavItem({
      name: 'FAQ',
      icon: 'help_outline',
      route: '/faq',
      subItems: [ ],
      position: 1
    });

    const seasons = new SidenavItem({
      name: 'Seasons',
      icon: 'star',
      route: '/seasons',
      subItems: [ ],
      position: 1,
      routerLinkActiveOptions: {
        exact: true
      }
    });

    const livery = new SidenavItem({
      name: 'ACC Livery Tool',
      icon: 'format_paint',
      route: '/livery-tool',
      subItems: [ ],
      position: 1
    });

    const patreon = new SidenavItem({
      name: 'Patreon',
      icon: 'thumb_up',
      route: '/patreon',
      subItems: [ ],
      position: 1
    });

    const seasonsSubItems = [
      new SidenavItem({
        name: 'Season 15',
        route: '/seasonsv2',
        icon: 'rating',
        parent: seasons,
        subItems: [ ],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        }
      }),

      new SidenavItem({
        name: 'ACC Balance Of Performance',
        route: '/seasonsv2/bop',
        icon: 'rating',
        parent: seasons,
        subItems: [ ],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        }
      }),

      new SidenavItem({
        name: 'ACC Connector Tutorial',
        route: '/acc-connector',
        icon: 'rating',
        parent: seasons,
        subItems: [ ],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        }
      }),

      new SidenavItem({
        name: 'Rules & Information',
        route: '/seasonsv2/rules',
        icon: 'rating',
        parent: seasons,
        subItems: [ ],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        }
      }),
      new SidenavItem({
        name: 'Penalties',
        route: '/seasonsv2/penalties',
        icon: 'rating',
        parent: seasons,
        subItems: [ ],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        }
      }),
      new SidenavItem({
        name: 'Season Archive',
        route: '/seasonsv2/archive',
        icon: 'clock',
        parent: seasons,
        subItems: [ ],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        }
      })
    ];

    seasons.subItems.push(...seasonsSubItems);

    const home = new SidenavItem({
      name: 'Home',
      icon: 'house',
      route: '/',
      subItems: [ ],
      position: 1
    });

    const statistics = new SidenavItem({
      name: 'Statistics',
      icon: 'show_chart',
      route: '/',
      subItems: [ ],
      position: 1
    });

    const account = new SidenavItem({
      name: 'My LFM',
      icon: 'account_circle',
      route: '/users',
      subItems: [ ],
      position: 1
    });

    const statisticsSubItems = [
      new SidenavItem({
        name: 'Rating Leaderboard',
        route: '/statistics/ratings',
        icon: 'rating',
        parent: statistics,
        subItems: [ ],
        position: 1,
        routerLinkActiveOptions: {
          exact: true
        }
      }),
      new SidenavItem({
        name: 'Safety Rating Leaderboard',
        route: '/statistics/safety-rating',
        icon: 'clock',
        parent: statistics,
        subItems: [ ],
        position: 1
      }),
      new SidenavItem({
        name: 'Most Active Drivers',
        route: '/statistics/most-active',
        icon: 'clock',
        parent: statistics,
        subItems: [ ],
        position: 1
      }),
      new SidenavItem({
        name: 'Driver Performance',
        route: '/statistics/driver-performance',
        icon: 'clock',
        parent: statistics,
        subItems: [ ],
        position: 1
      }),
      new SidenavItem({
        name: 'Track Records',
        route: '/tracks/records',
        icon: 'clock',
        parent: statistics,
        subItems: [ ],
        position: 1
      }) /*,
      new SidenavItem({
        name: 'Most Used Cars',
        route: '/statistics/most-used-cars',
        icon: 'clock',
        parent: statistics,
        subItems: [ ],
        position: 1
      })*/,
      new SidenavItem({
        name: 'Car Statistics',
        route: '/statistics/carstats',
        icon: 'clock',
        parent: statistics,
        subItems: [ ],
        position: 1
      }),
      new SidenavItem({
        name: 'User Development',
        route: '/statistics/usergrowth',
        icon: 'users',
        parent: statistics,
        subItems: [ ],
        position: 1
      }),
      new SidenavItem({
        name: 'Community Spread',
        route: '/statistics/community-spread',
        icon: 'users',
        parent: statistics,
        subItems: [ ],
        position: 1
      }),
      new SidenavItem({
        name: 'ELO Rating Distribution',
        route: '/statistics/elodistribution',
        icon: 'users',
        parent: statistics,
        subItems: [ ],
        position: 1
      }),
      new SidenavItem({
        name: 'Safety Rating Distribution',
        route: '/statistics/srdistribution',
        icon: 'users',
        parent: statistics,
        subItems: [ ],
        position: 1
      })
    ];

    statistics.subItems.push(...statisticsSubItems);

    const endurance = new SidenavItem({
      name: 'Endurance',
      icon: 'av_timer',
      route: '/endurance',
      subItems: [ ],
      position: 1
    });

    const admin = new SidenavItem({
      name: 'Admin',
      icon: 'account_circle',
      route: '/admin',
      subItems: [ ],
      position: 1
    });

    const teams = new SidenavItem({
      name: 'Teams',
      icon: 'supervised_user_circle',
      route: '/teams/list',
      subItems: [ ],
      position: 1
    });

    // Define Menu Items here

    // Top Level Item (The item to click on so the dropdown opens)
    const dashboard = new SidenavItem({
      name: 'Home',
      icon: 'dashboard',
      route: '/',
      subItems: [ ],
      position: 1
    });
    /*
    const setups = new SidenavItem({
      name: 'ACC Setups',
      icon: 'build',
      route: "https://coachdaveacademy.com/product/acc-setup-subscription/?ref=borisschalk",
      subItems: [ ],
      position: 1
    });*/

    const setups = new SidenavItem({
      name: 'Car Setups',
      icon: 'gosetups',
      route: "https://gosetups.gg?ref=lfm",
      subItems: [ ],
      position: 1
    });

    const hotlaps = new SidenavItem({
      name: 'ACC Hotlaps',
      icon: 'alarm_icon',
      route: "/hotlaps",
      subItems: [ ],
      position: 1
    });

    const merch = new SidenavItem({
      name: 'Merch',
      icon: 'add_shopping_cart',
      route: "https://www.getshirts.de/index.php?page=shop&id=low-fuel-motorsport",
      subItems: [ ],
      position: 1
    });


    // Send the created Menu structure to Redux/ngrx (you only need to send the Top Level Item, all dropdown items will be added automatically)
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(home));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(faq));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(seasons));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(events));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(hotlaps));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(livery));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(setups));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(teams));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(statistics));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(patreon));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(merch));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(team));
    this.store.dispatch(new fromSidenav.AddSidenavItemAction(esports));

  }


}
