import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {GlobalService} from '../../../global.service';
import {AuthenticationService} from '../../../authentication.service';

@Component({
  selector: 'elastic-toolbar-gamma',
  templateUrl: './toolbar-gamma.component.html',
  styleUrls: ['./toolbar-gamma.component.scss']
})
export class ToolbarGammaComponent implements OnInit {

  @Input() quickpanelOpen: boolean;

  @Output() toggledSidenav = new EventEmitter();
  @Output() toggledQuickpanel = new EventEmitter();

  constructor(private _globalService: GlobalService, private _auth: AuthenticationService) { }

  ngOnInit() {}

  toggleSidenav() {
    this.toggledSidenav.emit();
  }

  toggleQuickpanel() {
    this.toggledQuickpanel.emit();
  }

  loggedIn() {
    return this._auth.isLoggedIn();
  }

  login() {
    let url = window.location.href;
    window.location.href = this._globalService.apiUrl + '/../auth/steam?return_to=' + url;
    //window.location.href = this._globalService.apiUrl + '/../auth/steam?return_to=' + url;
  }

  openDiscordLink() {
    window.open(this._globalService.discordlink);
  }

}
