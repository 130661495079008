import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import {MatCardModule, MatDialogModule, MatProgressSpinnerModule, MatTabsModule, MatTooltipModule} from '@angular/material';
import {FooterModule} from './footer/footer.module';
import {RaceDetailModalComponent} from './events/race-detail-modal/race-detail-modal.component';
import {RaceDetailComponent} from './events/race-detail/race-detail.component';
import {PageHeaderModule} from '../core/page-header/page-header.module';
import {BreadcrumbsModule} from '../core/breadcrumbs/breadcrumbs.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { LfmlicenseComponent } from './lfmlicense/lfmlicense.component';
import { FaqComponent } from './lfmlicense/faq/faq.component';
import { LiveryToolComponent } from './livery-tool/livery-tool.component';


@NgModule({
    imports: [
        CommonModule,
        AuthModule,
        MatProgressSpinnerModule,
        FooterModule,
        PageHeaderModule,
        BreadcrumbsModule,
        FlexLayoutModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatTooltipModule,
        MatTabsModule
    ],
    exports: [
        LfmlicenseComponent
    ],
    declarations: [SpinnerOverlayComponent, LfmlicenseComponent, FaqComponent ]
})
export class PagesModule { }
